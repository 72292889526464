import React from "react";
import {
  Link,
  connect,
  TEXTFIELD,
  CircularProgress,
  toast,
  ToastContainer,
  Logo,
  Axios,
  PASSWORDFIELD,
  Row,
  Col,
  SUBMIT,
} from "../components";
import { API_LOGIN } from "../ApiUrl";

import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
} from "actions/Auth";

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errorMessage: "",
      // disable: true,
    };
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    // if (!email) {
    //   this.setState({ disable: true })
    // } else if (!password) {
    //   this.setState({ disable: true })
    // }
  };
  loginUser = (e) => {
    e.preventDefault();
    this.props.showAuthLoader();
    const { email, password } = this.state;

    const formData = {
      email: email.trim(),
      password: password.trim(),
    };

    Axios.post(API_LOGIN, formData)
      .then((response) => {
        const data = response.data;

        if (data.status == "failure") {
          toast.error(data.message);
        } else {
          console.log(data);
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("_id", data.data._id);
          localStorage.setItem("name", data.data.name);
          localStorage.setItem("role", data.data.role);
          if (data.data.permissions && data.data.permissions.length > 0) {
            localStorage.setItem(
              "permissions",
              JSON.stringify(data.data.permissions)
            );
          } else {
            localStorage.setItem("permissions", JSON.stringify([]));
          }
          localStorage.setItem("user_id", data.data.token);
          this.props.userSignInSuccess(data.data.token);
          toast.success("Login successfully.");
          setTimeout(() => {
            this.props.history.push("/app/dashboard/index");
          }, 1000);
        }
        this.props.hideAuthLoader();
      })
      .catch((err) => {
        console.log(err);
        let error = err.response;
        if (error !== undefined) {
          toast.error(error.data.message);
        } else {
          toast.error("server error, Please try after some time.");
        }
        this.props.hideAuthLoader();
      });
  };

  render() {
    const { email, password } = this.state;
    const { loader } = this.props;
    var diabled = !email || !password ? true : "";
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/">
              <img src={Logo} alt="Logo" className="logo" />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Sign In</h1>
            </div>

            <Row>
              <form onSubmit={(e) => this.loginUser(e)}>
                <Col xm="12">
                  <TEXTFIELD
                    label="Enter Email"
                    fullWidth
                    name="email"
                    onChange={this.handleChange}
                    value={email}
                    errors={{}}
                  />
                </Col>
                <Col xm="12">
                  <PASSWORDFIELD
                    label="Password"
                    fullWidth
                    name="password"
                    onChange={this.handleChange}
                    value={password}
                    errors={{}}
                  />
                </Col>
                <Col xm="12" md="7">
                  <SUBMIT title="Submit" disabled={diabled} />
                </Col>
              </form>
            </Row>
          </div>
        </div>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
})(SignIn);
